import request, { ResponsePageableData } from '@/libs/request'

/** 酒店列表 */
export const getHotelList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Hotel/getHotelList', data })

/** 创建酒店数据结构 */
export const createHotelRow = () => ({
	id: 0,
	/** 名称 */
	name: '',
	/** 地址 */
	address: '',
	/** 封面图 */
	cover_image: '',
	/** 标签（json） */
	label_ids: [] as (number | string)[],
	/** 原价（单位分） */
	original_price: 0,
	/** 价格（单位分） */
	price: 0,
	/** 设施（json） */
	facility_ids: [] as (number | string)[],
	area_adcode: [],
})

/** 酒店新增/编辑 */
export const editHotel = (data) => request({ url: '/adminapi/Hotel/editHotel', data })

/** 酒店删除 */
export const delHotel = (data?) => request({ url: '/adminapi/Hotel/delHotel', data })
